<template>
  <div class="my-4">
    <v-menu v-model="searchBox" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn width="100%" min-height="45" rounded v-bind="attrs" v-on="on">
          <v-icon>mdi-magnify</v-icon>Search...<v-spacer></v-spacer>
        </v-btn>
      </template>
      <v-card height="100%" width="100%">
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Legacy Client ID"
              v-model="form.legacyClientId"
              dense
              outlined
              class="mx-6 mt-6"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="First Name"
              v-model="form.firstName"
              dense
              outlined
              class="mx-6 mt-6"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Last Name"
              v-model="form.lastName"
              dense
              outlined
              class="mx-6 mt-6"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <div class="mx-6">
              <date-picker
                :field="{ label: 'Date Of Birth' }"
                :value="form.dateOfBirth"
                :validations="{ maxDate: new Date() }"
                @fieldChange="form.dateOfBirth = $event"
                outlined
              ></date-picker>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Year of Birth"
              v-model="form.year"
              dense
              outlined
              class="mx-6"
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="NYEISID"
              v-model="form.nyeisId"
              dense
              outlined
              class="mx-6"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <v-text-field
              label="OSIS"
              v-model="form.osis"
              v-mask="maskOsis"
              outlined
              dense
              class="mx-6"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="form.teamId"
              :items="teams"
              :item-text="
                (item) => `${item.leaderFirstName} ${item.leaderLastName}`
              "
              item-value="teamId"
              label="Team"
              clearable
              outlined
              dense
              class="mx-6"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="form.schoolId"
              :items="schools"
              item-text="schoolName"
              item-value="schoolId"
              label="School"
              clearable
              outlined
              dense
              class="mx-6"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="lookupClients"
            >Lookup Clients</v-btn
          >
          <v-btn small outlined class="mx-6" @click="clear">Clear </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <clients-table class="my-6" v-if="showTable"></clients-table>
  </div>
</template>

<script>
import ClientSearchOptions from "../../objs/ClientSearchOptions";
import ClientsTable from "./clients-table.vue";
export default {
  components: {
    ClientsTable,
  },
  data() {
    return {
      form: new ClientSearchOptions(),
      maskOsis: "###-###-###",
      searchBox: false,
    };
  },
  computed: {
    showTable() {
      return this.$store.state.clientModule.clients.length > 0;
    },
    teams() {
      return this.$store.state.optionsModule.teams;
    },
    schools() {
      return this.$store.state.optionsModule.schools;
    },
  },
  methods: {
    clear() {
      this.form = new ClientSearchOptions();
    },
    async lookupClients() {
      this.searchBox = false;
      this.$store.commit("uxModule/setShowLoader", true);
      let success = false;
      this.form.osis = this.form.osis?.replaceAll("-", "");
      if (this.$store.getters["securityModule/isAdmin"]) {
        success = await this.$store.dispatch("clientModule/searchClients", {
          ...this.form,
        });
      } else {
        success = await this.$store.dispatch(
          "clientModule/searchClientsForProvider",
          {
            ...this.form,
          }
        );
      }
      this.$store.commit("uxModule/setShowLoader", false);
      if (success) {
        if (!this.$store.state.clientModule.clients.length > 0) {
          this.$store.commit("uxModule/setSnackbarMsg", "No clients found");
          this.$store.commit("uxModule/setShowSnackbar", true);
        }
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error Retrieving Clients"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.clear();
    },
  },
};
</script>
