<template>
  <v-col cols="12" sm="3">
    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card
          :style="`border:2px solid ${color};`"
          :elevation="hover ? 24 : 2"
          @click="goToRoute(resource.name)"
        >
          <v-container class="vertical-center">
            <v-row align="end" :style="`height:${cardHeight}px;`">
              <v-col class="text-center">
                <icon
                  :color="color"
                  style="tile-icon"
                  class="fa-2x"
                  :icon="resource.icon"
                />
              </v-col>
            </v-row>

            <v-row align="start" :style="`height:${cardHeight}px;`">
              <v-col class="text-center" cols="12">
                <span class="font-weight-light" v-bind:style="{ color: color }">
                  {{ resource.title }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </template>
    </v-hover>
  </v-col>
</template>
<script>
export default {
  props: ["resource"],
  computed: {
    windowWidth: function () {
      return window.innerWidth;
    },
  },
  data() {
    return {
      cardHeight: 0,
      color: "#1c344b",
    };
  },
  created() {
    window.addEventListener("resize", this.reSizeWindow);
    this.reSizeWindow();
  },
  destroyed() {
    window.removeEventListener("resize", this.reSizeWindow);
  },
  watch: {
    windowWidth: function () {
      this.reSizeWindow();
    },
  },
  methods: {
    goToRoute: function (name) {
      this.$router.push({
        name,
      });
    },
    reSizeWindow() {
      if (window.innerWidth > 700) {
        this.cardHeight = window.innerWidth / 12;
      } else {
        this.cardHeight = 100;
      }
    },
  },
};
</script>