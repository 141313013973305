<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-checkbox
      v-model="val"
      :label="field.label"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    ></v-checkbox>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  computed: {
    val: {
      get: function () {
        return Boolean(this.value);
      },
      set: function (newValue) {},
    },
  },
  methods: {
    onChange(event) {
      this.$emit("fieldChange", event);
    },
  },
};
</script>