<template>
  <v-list-item v-if="item.onNavBar" :to="{ name: item.name }">
    <v-list-item-title>{{ item.title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
import { routes } from "../../router/routes";
export default {
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      routes,
    };
  },
};
</script>