import { formatDate } from "../utils/format-utils";

export default class SessionDetails {
  sessionId?: number;
  providerId?: number | null;
  providerName?: string | null;
  clientId?: number | null;
  clientName?: string | null;
  clientOsis?: string | null;
  clientDateOfBirth?: Date | null;
  district?: number | null;
  dateOfService?: Date | null;
  startTime?: Date | string | null;
  durationMinutes?: number | null;
  programId?: number | null;
  programName?: string | null;
  serviceId?: number | null;
  serviceName?: string | null;
  groupSize?: number | null;
  isBilingual?: boolean | null;
  language?: string | null;
  location?: string | null;
  serviceCoordinationNoteId?: number | null;
  scNoteFileId?: number | null;
  scSignatureId?: number | null;
  sessionNoteId?: number | null;
  sessionNoteFileId?: number | null;
  supervisorId?: number | null;
  supervisorSignatureId?: number | null;
  public constructor(params: SessionDetails = {} as SessionDetails) {
    let {
      sessionId = 0,
      providerId = null,
      providerName = "",
      clientId = null,
      clientName = "",
      clientOsis = "",
      clientDateOfBirth = null,
      district = null,
      dateOfService = null,
      startTime = null,
      durationMinutes = 0,
      programId = null,
      programName = "",
      serviceId = null,
      serviceName = "",
      groupSize = 0,
      isBilingual = false,
      language = "",
      location = "",
      serviceCoordinationNoteId = null,
      scNoteFileId = null,
      scSignatureId = null,
      sessionNoteId = null,
      sessionNoteFileId = null,
      supervisorId = null,
      supervisorSignatureId = null,
    } = params;
    this.sessionId = sessionId;
    this.providerId = providerId;
    this.providerName = providerName;
    this.clientId = clientId;
    this.clientName = clientName;
    this.clientOsis = clientOsis;
    this.clientDateOfBirth = clientDateOfBirth;
    this.district = district;
    this.dateOfService = dateOfService;
    this.startTime = startTime;
    this.durationMinutes = durationMinutes;
    this.programId = programId;
    this.programName = programName;
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.groupSize = groupSize;
    this.isBilingual = isBilingual;
    this.language = language;
    this.location = location;
    this.serviceCoordinationNoteId = serviceCoordinationNoteId;
    this.scNoteFileId = scNoteFileId;
    this.scSignatureId = scSignatureId;
    this.sessionNoteId = sessionNoteId;
    this.sessionNoteFileId = sessionNoteFileId;
    this.supervisorId = supervisorId;
    this.supervisorSignatureId = supervisorSignatureId;
  }

  get formattedClientDob() {
    return formatDate(this.clientDateOfBirth);
  }

  get formattedDateOfService() {
    return formatDate(this.dateOfService);
  }

  get requiresSupervisorSignature() {
    return this.supervisorId != null;
  }

  get noteId() {
    return this.serviceCoordinationNoteId ?? this.sessionNoteId;
  }
}
