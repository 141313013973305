<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn @click="goToNewSchool" color="primary" tile outlined class="ma-4"
        ><v-icon small>mdi-plus</v-icon>New School</v-btn
      ></v-row
    >
    <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      label="Search"
      single-line
      outlined
      rounded
      dense
      hide-details
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="schools"
      :search="search"
      class="alt-shading"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn text x-small color="primary" @click="goToSchool(item)">
          <v-icon small>mdi-pencil</v-icon></v-btn
        >
      </template></v-data-table
    >
  </div>
</template>

<script>
import SchoolForm from "./school-form";
export default {
  components: {
    SchoolForm,
  },
  data() {
    return {
      headers: [
        { text: "School Id", value: "schoolId", align: "left" },
        { text: "School Name", value: "schoolName" },
        { text: "Code", value: "schoolCode" },
        { text: "Contact", value: "contact" },
        { text: "Email Address", value: "emailAddress" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
  computed: {
    schools() {
      return this.$store.state.optionsModule.schools || null;
    },
  },
  methods: {
    goToNewSchool() {
      this.$router.push({ name: "NewSchool" });
    },
    goToSchool(item) {
      this.$router.push({ name: "SchoolForm", params: { schoolId: item.schoolId } });
    },
  },
};
</script>

<style lang="css" scoped>
.alt-shading >>> tr:nth-child(even) {
  background: #ccc;
}
</style>