<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-radio-group
      :value="value"
      :value-comparator="valueComparator"
      :label="field.label"
      :disabled="readonly"
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    >
      <v-radio
        v-for="(item, index) in field.options"
        :key="index"
        :label="item[field.itemText]"
        :value="item"
      ></v-radio>
    </v-radio-group>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  methods: {
    valueComparator: function (selected, item) {
      // use custom comparator function to get the selected value of radio group
      // because "value" is typeof object
      // so use "comparatorValue" property from "value" object to determine object equality
      // otherwise compare "itemText"
      return (
        selected &&
        this.field.comparatorValue &&
        selected.some(
          (val) =>
            val[this.field.comparatorValue] ===
              item[this.field.comparatorValue] ||
            val[this.field.itemText] === item[this.field.itemText]
        )
      );
    },
    onChange(event) {
      this.$emit("fieldChange", [event]);
    },
  },
};
</script>
