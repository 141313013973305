export default class UserSessionLog {
    userSessionLogId?: number | null;
    userId?: string | null;
    authenticatedAt?: Date | null;
    authenticatedUntil?: Date | null;
    userIPAddress?: string | null;
  
    public constructor(params: UserSessionLog = {} as UserSessionLog) {
      let {
        userSessionLogId = 0,
        userId = "",
        authenticatedAt = null,
        authenticatedUntil = null,
        userIPAddress = null,
      } = params;
      this.userSessionLogId = userSessionLogId;
      this.userId = userId;
      this.authenticatedAt = authenticatedAt;
      this.authenticatedUntil = authenticatedUntil;
      this.userIPAddress = userIPAddress;
    }
  }
  