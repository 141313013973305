<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    max-width="290"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <validation-provider :rules="validations" v-slot="{ errors }">
        <v-text-field
          :value="formattedDateValue"
          :label="field.label"
          :clearable="!readonly"
          @click:clear="clearDate"
          v-mask="mask"
          prepend-inner-icon="mdi-calendar"
          hint="MM/DD/YYYY format"
          @change="onDateTextInput"
          :error-messages="errors"
          v-on="on"
          color="#005c7b"
          :outlined="outlined"
          dense
        ></v-text-field>
      </validation-provider>
    </template>
    <v-date-picker
      :value="dateFormattedForPicker"
      @input="onDateChange"
      :disabled="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  props: ["field", "value", "validations", "readonly", "outlined"],
  data() {
    return {
      showDatePicker: false,
      mask: "##/##/####",
    };
  },
  directives: { mask },
  computed: {
    selectedDate: {
      get: function () {
        return this.value instanceof Date ? this.value : "";
      },
      set: function (newValue) {},
    },
    formattedDateValue: function () {
      if (!this.dateValueEmpty) {
        return [
          (this.selectedDate.getMonth() + 1).toString().padStart(2, "0"),
          this.selectedDate.getDate().toString().padStart(2, "0"),
          this.selectedDate.getFullYear(),
        ].join("/");
      }
      return "";
    },
    dateValueEmpty: function () {
      return this.selectedDate === "";
    },
    dateFormattedForPicker: function () {
      if (!this.dateValueEmpty) {
        return this.selectedDate.toISOString().substr(0, 10);
      }
      return "";
    },
  },
  methods: {
    onDateTextInput: function (event) {
      if (event == null) {
        this.clearDate();
      } else {
        this.showDatePicker = false;
        const [month, day, year] = event.split("/");
        let date = new Date(year, month - 1, day, 0, 0, 0, 0);
        this.selectedDate = date;
        this.$emit("fieldChange", date);
      }
    },
    onDateChange: function (event) {
      this.showDatePicker = false;
      const [year, month, day] = event.split("-");
      let date = new Date(year, month - 1, day, 0, 0, 0, 0);
      this.selectedDate = date;
      this.$emit("fieldChange", date);
    },
    clearDate: function () {
      this.selectedDate = "";
      this.$emit("fieldChange", "");
    },
  },
};
</script>
