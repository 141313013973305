<template>
  <v-item-group>
    <v-item v-for="(item, index) in navigationItems" :key="index">
      <v-btn v-if="!item.children" text :to="item.path" height="62">
        {{ item.title }}
      </v-btn>

      <v-menu
        v-else
        offset-y
        open-on-hover
        :close-on-content-click="false"
        text
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" height="62">
            {{ item.title }}
          </v-btn>
        </template>
        <v-list>
          <top-sub-item
            v-for="(item, index) in item.children"
            :key="index"
            :item="item"
          >
          </top-sub-item>
        </v-list>
      </v-menu>
    </v-item>
  </v-item-group>
</template>

<script>
import { routes } from "../../router/routes";
import { showItem } from "../../router/index";
import TopSubItem from "./top-sub-item";

export default {
  components: {
    TopSubItem,
  },
  data() {
    return {
      routes,
    };
  },
  computed: {
    navigationItems: function () {
      return this.routes
        .filter((r) => {
          return showItem(r);
        })
        .map((item) => {
          return {
            ...item,
            children: item.children.filter((r) => {
              return showItem(r);
            }),
          };
        });
    },
  },
};
</script>
