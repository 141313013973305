import Oidc from "oidc-client";
import store from "../store/index";

var mgr = new Oidc.UserManager({
  authority: "https://is4.omnisecurityportal.com",
  client_id: "office",
  redirect_uri: "https://office.omniuserportal.com/callback",
  response_type: "id_token token",
  scope: "openid profile omniapi",
  post_logout_redirect_uri: "https://office.omniuserportal.com/",
  // automaticSilentRenew: true,
  silent_redirect_uri:
    "https://office.omniuserportal.com/static/silent-renew.html",
  // accessTokenExpiringNotificationTime: 10,
});

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

mgr.events.addAccessTokenExpiring(function () {
  // default - 60 seconds before expiration
  console.log("access token expiring...");
  store.dispatch("securityModule/shallowAuthenticate");
});

export default mgr;
