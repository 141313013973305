export default class DistrictAdmin {
    districtAdminId?: number | null;
    legacyProviderId?: number | null;
    lastName?: string | null;
    firstName?: string | null;
    title?: string | null;
    districtId?: number | null;
    streetAddress?: string | null;
    streetAddressLine2?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    phone?: string | null;
    emailAddress?: string | null;

    public constructor(params: DistrictAdmin = {} as DistrictAdmin) {
        let {
            districtAdminId = 0,
            legacyProviderId = 0,
            lastName = "",
            firstName = "",
            title = "",
            districtId = null,
            streetAddress = "",
            streetAddressLine2 = "",
            city = "",
            state = "",
            zipCode = "",
            phone = "",
            emailAddress = ""
        } = params;
        this.districtAdminId = districtAdminId;
        this.legacyProviderId = legacyProviderId;
        this.lastName = lastName;
        this.firstName = firstName;
        this.title = title;
        this.districtId = districtId;
        this.streetAddress = streetAddress;
        this.streetAddressLine2 = streetAddressLine2;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.phone = phone;
        this.emailAddress = emailAddress
    }
}