export default class Team {
    teamId?: number | null;
    legacyTeamId?: string | null;
    leaderId?: number | null;
    leaderFirstName?: string | null;
    leaderLastName?: string | null; 
    constructor (params: Team = {} as Team) {
        let {
            teamId = 0,
            legacyTeamId = "",
            leaderId = null,
            leaderFirstName = "",
            leaderLastName = ""
          } = params;
          this.teamId = teamId;
          this.legacyTeamId = legacyTeamId;
          this.leaderId = leaderId;
          this.leaderFirstName = leaderFirstName;
          this.leaderLastName = leaderLastName;
    }
}
