export default class ClientSearchOptions {
    clientId?: number | null;
    legacyClientId?: number | null;
    dateOfBirth?: Date | null;
    year?: number | null;
    osis?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    status?: boolean | null;
    nyeisId?: string | null;
    schoolId?: number | null;
    teamId?: number | null;

    public constructor(params: ClientSearchOptions = {} as ClientSearchOptions) {
        let {
            clientId = null,
            legacyClientId = null,
            dateOfBirth = null,
            year = null,
            osis = null,
            firstName = null,
            lastName = null,
            status = null,
            nyeisId = null,
            schoolId = null,
            teamId = null,
        } = params;
        this.clientId = clientId;
        this.legacyClientId = legacyClientId;
        this.dateOfBirth = dateOfBirth;
        this.year = year;
        this.osis = osis;
        this.firstName = firstName;
        this.lastName = lastName;
        this.status = status;
        this.nyeisId = nyeisId;
        this.schoolId = schoolId;
        this.teamId = teamId;
    }
}