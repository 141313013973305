<template>
  <v-card height="100%">
    <v-data-table
      :headers="headers"
      :items="clientSchools"
      sort-by="effectiveDate"
      sort-desc
      :item-class="formatCurrent"
    >
      <template v-if="isAdmin" v-slot:[`top`]="{}">
        <v-row>
          <v-spacer></v-spacer>
          <client-school-form></client-school-form>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <client-school-form :clientSchool="item"></client-school-form>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ClientSchoolForm from "./client-school-form";
export default {
  components: { ClientSchoolForm },
  data() {
    return {
      headers: [
        { text: "School Name", value: "school.schoolName" },
        { text: "School Contact", value: "school.contact" },
        { text: "Phone", value: "school.phone" },
        { text: "Effective Date", value: "formattedEffectiveDate" },
        { text: "End Date", value: "formattedEndDate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    clientSchools() {
      return (
        this.$store.state.clientModule.selectedClient.clientSchools || null
      );
    },
  },
  methods: {
    formatCurrent(item) {
      let today = new Date();
      if (item.effectiveDate <= today && item.endDate >= today) {
        return "elevation-5";
      }
    },
  },
};
</script>