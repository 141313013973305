import Team from "./Team";
import { formatPhoneNumber } from "../utils/format-utils";

export default class School {
    schoolId?: number | null;
    legacySchoolId?: number | null;
    schoolName?: string | null;
    teamId?: number | null;
    team?: Team | null;
    summerCamp?: boolean | null;
    schoolBoro?: string | null;
    districtId?: number | null;
    schoolCode?: string | null;
    secondarySchoolBoro?: string | null;
    secondaryDistrictId?: number | null;
    secondarySchoolCode?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipCode?: string | null;
    emailAddress?: string | null;
    phone?: string | null;
    phoneExtension?: string | null;
    secondaryPhone?: string | null;
    secondaryPhoneExtension?: string | null;
    fax?: string | null;
    contact?: string | null;
    notes?: string | null;

     constructor (params: School = {} as School) {
        let {
            schoolId = 0,
            legacySchoolId = 0,
            schoolName = "",
            teamId = null,
            team = null,
            summerCamp = false,
            schoolBoro = "",
            districtId = null,
            schoolCode = "",
            secondarySchoolBoro = "",
            secondaryDistrictId = null,
            secondarySchoolCode = "",
            streetAddress = "",
            city = "",
            state = "",
            zipCode = "",
            emailAddress = "",
            phone = "",
            phoneExtension = "",
            secondaryPhone = "",
            secondaryPhoneExtension = "",
            fax = "",
            contact = "",
            notes = ""
          } = params;
          this.schoolId = schoolId;
          this.legacySchoolId = legacySchoolId;
          this.schoolName = schoolName;
          this.teamId = teamId;
          this.team = team && new Team(team);
          this.summerCamp = summerCamp;
          this.schoolBoro = schoolBoro;
          this.districtId = districtId;
          this.schoolCode = schoolCode;
          this.secondarySchoolBoro = secondarySchoolBoro;
          this.secondaryDistrictId = secondaryDistrictId;
          this.secondarySchoolCode = secondarySchoolCode;
          this.streetAddress = streetAddress;
          this.city = city;
          this.state = state;
          this.zipCode = zipCode;
          this.emailAddress = emailAddress;
          this.phone = phone && formatPhoneNumber(phone);
          this.phoneExtension = phoneExtension;
          this.secondaryPhone = secondaryPhone && formatPhoneNumber(secondaryPhone);
          this.secondaryPhoneExtension = secondaryPhoneExtension;
          this.fax = fax;
          this.contact = contact;
          this.notes = notes;
        }
}