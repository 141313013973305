<template>
  <div>
    <component
      v-bind:is="field.componentName"
      :field="field"
      :value="field.value"
      :readonly="readonly"
      :outlined="field.outlined"
      @fieldChange="$emit('fieldChange', $event)"
      :validations="validations"
    ></component>
  </div>
</template>

<script>
import TextField from "./text-field";
import TextArea from "./text-area";
import Checkbox from "./checkbox-field";
import DatePicker from "./date-picker-field";
import TimeInputField from "./time-input-field";
import StandardSelect from "./standard-select";
import MultipleSelect from "./multiple-select";
import RadioGroup from "./radio-group";
import PhoneNumberField from "./phone-number-field";
import ZipCodeField from "./zip-code-field";
import Autocomplete from "./autocomplete";
import NumericField from "./numeric-field";
import TextEditor from "./text-editor";
export default {
  name: "form-field",
  props: {
    field: Object,
    readonly: Boolean,
    validations: Object,
  },

  components: {
    "text-field": TextField,
    "text-area": TextArea,
    checkbox: Checkbox,
    "date-picker": DatePicker,
    "time-input": TimeInputField,
    "standard-select": StandardSelect,
    "multiple-select": MultipleSelect,
    "radio-group": RadioGroup,
    "phone-number-field": PhoneNumberField,
    "zip-code-field": ZipCodeField,
    autocomplete: Autocomplete,
    "numeric-field": NumericField,
    "text-editor": TextEditor,
  },
};
</script>
