<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="70vw">
      <v-card light id="screenshotCard">
        <validation-observer v-slot="{ invalid }">
          <v-card-title class="headline grey lighten-2" primary-title>
            We welcome your feedback!
          </v-card-title>
          <div class="mx-auto my-6" style="width: 60vw">
            <v-img
              style="outline-style: solid 1px"
              :src="imageBase64URL"
            ></v-img>
          </div>
          <v-row>
            <v-col cols="11">
              <v-card-text class="blockquote ml-8" style="text-align: left">
                Please describe, in specific detail, what you would like
                changed. We appreciate your feedback, and will use it to
                evaluate features and make improvements to our apps.
              </v-card-text>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" class="ml-10">
              <validation-provider
                :rules="validations.message"
                v-slot="{ errors }"
                ><v-textarea
                  outlined
                  class="ma-4"
                  placeholder="Write your comments here."
                  v-model="message"
                  :error-messages="errors"
                  color="#005c7b"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11" class="ml-10">
              <validation-provider
                :rules="validations.fromEmailAddress"
                v-slot="{ errors }"
                ><v-text-field
                  class="ma-4"
                  outlined
                  label="Enter your email address."
                  v-model="fromEmailAddress"
                  :error-messages="errors"
                  color="#005c7b"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :dark="!invalid"
              :disabled="invalid"
              color="#005c7b"
              @click="uploadAndSend"
            >
              Send Feedback
            </v-btn>
            <v-btn color="#005c7b" outlined @click="cancel"> Cancel </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { mapActions } from "vuex";
import FeedbackParams from "../../objs/Shared/FeedbackParams";
import {
  required,
  email,
  ValidFeedbackEmail,
} from "../../plugins/vee-validate/validation";
import { EventBus } from "../Shared/event-bus.js";

export default {
  created: function () {
    const scope = this;
    EventBus.$on("trigger-feedback-form", function (data) {
      scope.capture();
    });
  },
  data() {
    return {
      dialog: false,
      sendButtonClicked: false,
      message: "",
      fromEmailAddress: "",
      imageBase64URL: null,
    };
  },
  computed: {
    senderEmailAddress: function () {
      return this.fromEmailAddress;
    },
    image: function () {
      return this.imageBase64URL.split("base64,").pop();
    },
    formParams: function () {
      return new FeedbackParams({
        fromName: "Omni System User",
        subject: "Omni System Feedback",
        message: this.message,
        fromEmailAddress: this.senderEmailAddress,
        image: this.image,
      });
    },
    validations: function () {
      return {
        message: {
          required: true,
        },
        fromEmailAddress: {
          required: true,
          email: true,
          ValidFeedbackEmail: true,
        },
      };
    },
  },
  methods: {
    ...mapActions("sharedModule", ["sendFeedbackEmail"]),
    capture: function () {
      html2canvas(document.getElementById("app"), {
        scrollX: 0,
        scrollY: -window.scrollY,
      }).then((canvas) => {
        this.imageBase64URL = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        this.dialog = true;
      });
    },
    uploadAndSend: function () {
      this.dialog = false;
      this.sendFeedbackEmail({ feedbackParams: this.formParams });
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
