<template>
  <v-card class="mb-4">
    <v-card-title
      >Search for sessions
      <v-btn @click="toggle()" icon>
        <v-icon>{{ iconText }} </v-icon></v-btn
      ></v-card-title
    >
    <v-card-subtitle v-if="expanded">
      <v-row>
        <v-radio-group v-model="query" row>
          <v-radio
            v-for="query in sessionQueries"
            :key="query"
            :label="query"
            :value="query"
          ></v-radio>
        </v-radio-group>
      </v-row>
      <v-row>
        <v-col v-if="showProviderField" cols="12" sm="3">
          <v-autocomplete
            label="Provider"
            v-model="providerId"
            :items="providerOptions"
            :item-text="
              (item) =>
                `${item.firstName} ${item.lastName} (${item.legacyProviderId})`
            "
            item-value="providerId"
            :loading="providerOptions.length === 0"
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col v-if="showClientField" cols="12" sm="3">
          <v-autocomplete
            label="Client"
            v-model="clientId"
            :items="clientOptions"
            :item-text="(item) => `${item.firstName} ${item.lastName}`"
            item-value="clientId"
            :loading="clientOptions.length === 0"
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col v-if="showSupervisorField" cols="12" sm="3">
          <v-autocomplete
            label="Supervisor"
            v-model="supervisorId"
            :items="supervisorOptions"
            :item-text="
              (item) =>
                `${item.firstName} ${item.lastName} (${item.legacyProviderId})`
            "
            item-value="providerId"
            :loading="supervisorOptions.length === 0"
            dense
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col v-if="showDateField" cols="12" sm="3">
          <date-picker
            :value="date"
            :field="{ label: 'Date of Service' }"
            @fieldChange="date = $event"
          ></date-picker>
        </v-col>
        <v-col v-if="showDateRangeFields" cols="12" sm="3">
          <date-picker
            :value="startDate"
            :field="{ label: 'Start Date' }"
            @fieldChange="startDate = $event"
          ></date-picker>
        </v-col>
        <v-col v-if="showDateRangeFields" cols="12" sm="3">
          <date-picker
            :value="endDate"
            :field="{ label: 'End Date' }"
            @fieldChange="endDate = $event"
          ></date-picker>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-actions v-if="expanded">
      <v-row>
        <v-col cols="12" sm="2">
          <v-btn
            @click="getSessions"
            color="primary"
            :dark="!loading"
            small
            :loading="loading"
            :disabled="loading"
          >
            Lookup Sessions
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { SessionQueries } from "../../objs/SessionQueries";

export default {
  props: ["expanded"],
  data: function () {
    return {
      sessionQueries: Object.values(SessionQueries),
      query: SessionQueries.PROVIDER_AND_DATE,
      providerId: "",
      clientId: "",
      date: "",
      startDate: "",
      endDate: "",
      supervisorId: "",
      loading: false,
    };
  },
  async mounted() {
    if (this.providerOptions.length === 0) {
      await this.$store.dispatch("optionsModule/getAllProviders");
    }
    if (this.clientOptions.length === 0) {
      await this.$store.dispatch("optionsModule/getAllClients");
    }
  },
  computed: {
    iconText() {
      return this.expanded ? "mdi-chevron-up" : "mdi-chevron-down";
    },
    providerOptions() {
      return this.$store.state.optionsModule.providers;
    },
    clientOptions() {
      return this.$store.state.optionsModule.clients;
    },
    supervisorOptions() {
      return this.$store.state.optionsModule.supervisors;
    },
    showProviderField: function () {
      return [
        SessionQueries.PROVIDER_AND_CLIENT,
        SessionQueries.PROVIDER_AND_DATE,
        SessionQueries.PROVIDER_AND_DATE_RANGE,
      ].includes(this.query);
    },
    showClientField: function () {
      return SessionQueries.PROVIDER_AND_CLIENT === this.query;
    },
    showDateField: function () {
      return [
        SessionQueries.PROVIDER_AND_DATE,
        SessionQueries.DATE_OF_SERVICE,
      ].includes(this.query);
    },
    showDateRangeFields: function () {
      return [
        SessionQueries.PROVIDER_AND_DATE_RANGE,
        SessionQueries.SUPERVISOR_AND_DATE_RANGE,
      ].includes(this.query);
    },
    showSupervisorField: function () {
      return SessionQueries.SUPERVISOR_AND_DATE_RANGE === this.query;
    },
  },
  methods: {
    toggle() {
      this.$emit("toggleExpand");
    },
    async getSessions() {
      let success = false;
      this.loading = true;

      switch (this.query) {
        case SessionQueries.PROVIDER_AND_DATE:
          success = await this.$store.dispatch(
            "sessionsModule/findByProviderAndDate",
            { providerId: this.providerId, dateOfService: this.date }
          );
          break;
        case SessionQueries.PROVIDER_AND_DATE_RANGE:
          success = await this.$store.dispatch(
            "sessionsModule/findByProviderAndDateRange",
            {
              providerId: this.providerId,
              startDate: this.startDate,
              endDate: this.endDate,
            }
          );
          break;
        case SessionQueries.PROVIDER_AND_CLIENT:
          success = await this.$store.dispatch(
            "sessionsModule/findByProviderAndClient",
            { providerId: this.providerId, clientId: this.clientId }
          );
          break;
        case SessionQueries.DATE_OF_SERVICE:
          success = await this.$store.dispatch(
            "sessionsModule/findByDateOfService",
            { dateOfService: this.date }
          );
          break;
        case SessionQueries.SUPERVISOR_AND_DATE_RANGE:
          success = await this.$store.dispatch(
            "sessionsModule/findBySupervisorAndDateRange",
            {
              supervisorId: this.supervisorId,
              startDate: this.startDate,
              endDate: this.endDate,
            }
          );
      }

      this.loading = false;

      if (success) {
        this.$emit("showTable");
      } else {
        this.$store.commit("uxModule/setSnackbarMsg", "No sessions found.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
