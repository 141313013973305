import Client from "./Client";
import School from "./School";
import { formatDate } from "../utils/format-utils";

export default class ClientSchool {
    clientSchoolId?: number | null;
    legacyClientSchoolId?: number | null;
    legacyClientId?: number | null;
    clientId?: number | null;
    client?: Client | null;
    legacySchoolId?: number | null;
    schoolId?: number | null;
    school?: School | null;
    semesterId?: number | null;
    effectiveDate?: Date | null;
    endDate?: Date | null;
    subSchoolId?: number | null;
    subSchool?: School | null;
    subSchoolEffectiveDate?: Date | null;
    subSchoolEndDate?: Date | null;
     constructor (params: ClientSchool = {} as ClientSchool) {
        let {
            clientSchoolId = 0,
            legacyClientSchoolId = 0,
            legacyClientId = 0,
            clientId = 0,
            client = null,
            legacySchoolId = 0,
            schoolId = 0,
            school = null,
            semesterId = null,
            effectiveDate = null,
            endDate = null,
            subSchoolId = null,
            subSchool = null,
            subSchoolEffectiveDate = null,
            subSchoolEndDate = null
          } = params;
          this.clientSchoolId = clientSchoolId;
          this.legacyClientSchoolId = legacyClientSchoolId;
          this.legacyClientId = legacyClientId;
          this.clientId = clientId;
          this.client = client;
          this.legacySchoolId = legacySchoolId;
          this.schoolId = schoolId;
          this.school = school && new School(school);
          this.semesterId = semesterId;
          this.effectiveDate = effectiveDate && new Date(effectiveDate);
          this.endDate = endDate && new Date(endDate);
          this.subSchoolId = subSchoolId;
          this.subSchool = subSchool && new School(subSchool);
          this.subSchoolEffectiveDate = subSchoolEffectiveDate && new Date(subSchoolEffectiveDate);
          this.subSchoolEndDate = subSchoolEndDate && new Date(subSchoolEndDate);
        }
  get formattedEffectiveDate() { return formatDate(this.effectiveDate); }
  get formattedEndDate() { return formatDate(this.endDate); }

}