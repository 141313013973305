import axios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "file-saver";

export const downloadFileUrl = async (fileUrl, fileName) => {
  axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    downloadFile(response.data, fileName);
  });
};

export const downloadFile = async (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadZip = async (fileDownloads, zipFileName) => {
  var zip = new JSZip();
  var count = 0;

  fileDownloads.forEach(async (fileDownload) => {
    try {
      const data = await JSZipUtils.getBinaryContent(fileDownload.url);
      zip.file(fileDownload.fileName, data, { binary: true });
      count++;
      if (count === fileDownloads.length) {
        await zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, zipFileName);
        });
      }
    } catch (err) {
      throw err;
    }
  });
};
