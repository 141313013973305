<template>
  <v-dialog v-model="dialog" persistent width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" @click="dialog = true" color="primary">
        <v-icon small>mdi-signature</v-icon> Sign
      </v-btn>
    </template>
    <v-card>
      <v-card-title color="primary">
        Please Sign Here
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-subtitle-1 pb-0">
        <VueSignaturePad
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
            onEnd: () => {
              disabled = false;
            },
          }"
          ref="signaturePad"
          id="signature"
          width="100%"
          height="150px"
        />
        <v-checkbox
          :disabled="disabled"
          :value="Boolean(consentedAt)"
          @change="onCheck"
          label="I consent to the use of my electronic
        signature as a valid equivalent to my manual/handwritten signature."
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn outlined color="primary" @click="undo"
          ><v-icon>mdi-undo</v-icon>Undo</v-btn
        >
        <v-btn outlined color="primary" @click="clear"
          ><v-icon>mdi-eraser</v-icon>Clear</v-btn
        >
        <v-btn
          :disabled="this.disabled || !Boolean(this.consentedAt)"
          outlined
          color="primary"
          @click="save"
          ><v-icon>mdi-signature</v-icon>Sign</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    disabled: true,
    consentedAt: null,
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
      this.disabled = this.$refs.signaturePad.isEmpty();
      if (!Boolean(this.consentedAt) || this.$refs.signaturePad.isEmpty()) {
        this.consentedAt = null;
      }
    },
    save: async function () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.$emit("saveSignature", this.consentedAt, data);
      this.dialog = false;
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.disabled = true;
      this.consentedAt = null;
    },
    closeDialog() {
      this.clear();
      this.dialog = false;
    },
    onCheck(checked) {
      if (checked) {
        this.consentedAt = new Date();
      } else {
        this.consentedAt = null;
      }
    },
  },
};
</script>

<style>
#signature {
  border: 3px solid black;
}
</style>