export default class Semester {
    semesterId?: number | null;
    semesterCode?: string | null;
    semesterDescription?: string | null;
    summerSemester?: boolean | null;
    semesterStartDate?: Date | null;
    semesterEndDate?: Date | null;

    public constructor(params: Semester = {} as Semester) {
        let {
            semesterId = null,
            semesterCode = null,
            semesterDescription = null,
            summerSemester = null,
            semesterStartDate = null,
            semesterEndDate = null
        } = params;
        this.semesterId = semesterId;
        this.semesterCode = semesterCode;
        this.semesterDescription = semesterDescription;
        this.summerSemester = summerSemester;
        this.semesterStartDate = semesterStartDate;
        this.semesterEndDate = semesterEndDate;
    }
}