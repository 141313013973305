export default class Program {
    programId?: number;
    code?: string | null;
    name?: string | null;
  
    public constructor(params: Program = {} as Program) {
      let { programId = 0, code = "", name = "" } = params;
      this.programId = programId;
      this.code = code;
      this.name = name;
    }
  }
  