export default class UserProfile {
  id?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legacySystemId?: string | null;
  email?: string | null;
  roles?: string[] | null;

  public constructor(params: UserProfile = {} as UserProfile) {
    let {
      id = "",
      userName = "",
      firstName = "",
      lastName = "",
      legacySystemId = "",
      email = "",
      roles = new Array<string>(),
    } = params;
    this.id = id;
    this.userName = userName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.legacySystemId = legacySystemId;
    this.email = email;
    this.roles = roles;
  }
}
