<template>
  <v-dialog v-model="dialog" persistent width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="Boolean(clientSchool)"
        v-on="on"
        @click="load"
        text
        x-small
        color="primary"
      >
        <v-icon small>mdi-pencil</v-icon></v-btn
      >
      <v-btn
        v-else
        v-on="on"
        @click="dialog = true"
        small
        color="primary"
        tile
        outlined
        class="ma-4"
      >
        <v-icon small>mdi-plus</v-icon> New Registration
      </v-btn> </template
    ><v-card>
      <v-card-title color="primary">
        School Registration
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn> </v-card-title
      ><v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                v-model="selectedSemester"
                label="Semester"
                :items="semesters"
                :item-text="
                  (item) => `${item.semesterCode} - ${item.semesterDescription}`
                "
                @change="autopopulateSemesterDates"
                return-object
                :error-messages="errors"
                :clearable="isAdmin"
                :readonly="!isAdmin"
              ></v-autocomplete> </validation-provider></v-col
        ></v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-autocomplete
                v-model="form.school"
                label="School"
                :items="filteredSchools"
                :item-text="(item) => `${item.schoolName}`"
                return-object
                :error-messages="errors"
                :clearable="isAdmin"
                :readonly="!isAdmin"
              ></v-autocomplete> </validation-provider></v-col
        ></v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="pt-4">
              <date-picker
                :field="{ label: 'Effective Date' }"
                :value="form.effectiveDate"
                :validations="{ required: true }"
                @fieldChange="form.effectiveDate = $event"
                :readonly="!isAdmin"
              >
              </date-picker></div></v-col
          ><v-col cols="12" sm="6">
            <div class="pt-4">
              <date-picker
                :field="{ label: 'End Date' }"
                :value="form.endDate"
                :validations="{ required: true }"
                @fieldChange="form.endDate = $event"
                :readonly="!isAdmin"
              >
              </date-picker></div></v-col
        ></v-row>
        <v-divider></v-divider>
        <v-checkbox
          v-model="hasSubSchool"
          label="Is Special Program"
          :readonly="!isAdmin"
        ></v-checkbox>
        <div v-if="Boolean(hasSubSchool)">
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete
                v-model="form.subSchool"
                label="Sub School"
                :items="filteredSchools"
                :item-text="(item) => `${item.schoolName}`"
                return-object
                :clearable="isAdmin"
                :readonly="!isAdmin"
              ></v-autocomplete></v-col
          ></v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="pt-4">
                <date-picker
                  :field="{ label: 'Effective Date' }"
                  :value="form.subSchoolEffectiveDate"
                  @fieldChange="form.subSchoolEffectiveDate = $event"
                  :readonly="!isAdmin"
                >
                </date-picker></div></v-col
            ><v-col cols="12" sm="6">
              <div class="pt-4">
                <date-picker
                  :field="{ label: 'End Date' }"
                  :value="form.subschoolEndDate"
                  @fieldChange="form.subschoolEndDate = $event"
                  :readonly="!isAdmin"
                >
                </date-picker></div></v-col
          ></v-row>
        </div>
      </v-card-text>
      <v-card-actions v-if="isAdmin">
        <v-spacer></v-spacer>
        <v-btn small color="primary" text @click="closeDialog">Cancel</v-btn>
        <v-btn small color="primary" disabled @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ClientSchool from "../../../objs/ClientSchool";
export default {
  props: ["clientSchool"],
  data() {
    return {
      form: new ClientSchool(),
      dialog: false,
      selectedSemester: null,
      hasSubSchool: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    semesters() {
      return this.$store.state.optionsModule.semesters || [];
    },
    filteredSchools() {
      return this.$store.getters[
        "optionsModule/getFilteredSchoolsByIsSummerCamp"
      ](this.selectedSemester?.summerSemester ?? false);
    },
  },
  methods: {
    autopopulateSemesterDates() {
      if (this.selectedSemester) {
        this.form.effectiveDate = new Date(
          this.selectedSemester.semesterStartDate
        );
        this.form.endDate = new Date(this.selectedSemester.semesterEndDate);
      } else {
        this.form.effectiveDate = "";
        this.form.endDate = "";

        // this.form.subSchoolEffectiveDate = "";
        // this.form.subSchoolEndDate = "";
      }
    },
    load() {
      this.form = { ...this.clientSchool };
      this.selectedSemester = this.semesters.find(
        (item) => item.semesterId === this.clientSchool.semesterId
      );
      this.hasSubSchool = Boolean(this.clientSchool.subSchool);
    },
    closeDialog() {
      this.dialog = false;
    },
    async save() {
      let success = false;
      if (this.clientSchool) {
        success = await this.$store.dispatch(
          "clientModule/updateClientSchool",
          this.form
        );
      } else {
        success = await this.$store.dispatch(
          "clientModule/createClientSchool",
          this.form
        );
      }
      if (success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Client registration saved successfully"
        );
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error saving client school"
        );
      }
      this.$store.commit("uxModule/setShowSnackbar", true);
    },
  },
};
</script>