<template>
  <v-tabs>
    <v-tab v-if="Boolean(client)"
      ><span class="font-weight-black">Profile</span></v-tab
    >
    <v-tab v-if="Boolean(client)"
      ><span
        :class="
          Boolean(client.clientSchools.length) ? 'font-weight-black' : null
        "
        >Schools</span
      ></v-tab
    >
    <v-tab v-if="!isProvider"
      ><span
        :class="
          Boolean(serviceCoordinationNotes.length) ? 'font-weight-black' : null
        "
        >SC Notes</span
      >
      <v-badge
        :content="numAwaitingSCSignature"
        :value="Boolean(numAwaitingSCSignature)"
        color="error"
        bordered
      ></v-badge
    ></v-tab>
    <v-tab v-if="isAdmin || Boolean(client)"
      ><span :class="Boolean(sessionNotes.length) ? 'font-weight-black' : null"
        >Session Notes</span
      ><v-badge
        :content="numAwaitingSupervisorSignature"
        :value="Boolean(numAwaitingSupervisorSignature)"
        color="error"
        bordered
      ></v-badge
    ></v-tab>
    <v-tab-item v-if="Boolean(client)">
      <client-profile :client="client"></client-profile>
    </v-tab-item>
    <v-tab-item v-if="Boolean(client)">
      <client-schools-table></client-schools-table>
    </v-tab-item>
    <v-tab-item v-if="!isProvider">
      <notes-table
        :clientId="Boolean(client) ? client.clientId : null"
        :isServiceCoordination="true"
        :notes="serviceCoordinationNotes"
      ></notes-table>
    </v-tab-item>
    <v-tab-item v-if="isAdmin || Boolean(client)">
      <notes-table
        :clientId="Boolean(client) ? client.clientId : null"
        :notes="sessionNotes"
      >
      </notes-table>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import ClientProfile from "../Chart/client-profile";
import ClientSchoolsTable from "../Chart/client-schools-table";
import NotesTable from "./notes-table";
export default {
  props: ["client"],
  components: {
    ClientProfile,
    ClientSchoolsTable,
    NotesTable,
  },
  computed: {
    providerId() {
      return this.$store.state.sharedModule.providerId;
    },
    serviceCoordinationNotes() {
      return this.$store.getters["sessionsModule/getServiceCoordinationNotes"];
    },
    sessionNotes() {
      return this.$store.getters["sessionsModule/getSessionNotes"];
    },
    numAwaitingSCSignature() {
      return this.serviceCoordinationNotes.filter(
        (item) => item.providerId === this.providerId && !item.scSignatureId
      ).length;
    },
    numAwaitingSupervisorSignature() {
      return this.sessionNotes.filter(
        (item) =>
          item.supervisorId === this.providerId && !item.supervisorSignatureId
      ).length;
    },
    isAdmin() {
      return this.$store.getters["securityModule/isAdmin"];
    },
    isProvider() {
      return this.$store.getters["securityModule/isProvider"];
    },
  },
};
</script>