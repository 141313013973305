 export default class EIClient {
    eiClientId?: number | null;
    clientId?: number | null;
    eiReferralDate?: Date | null;
    eiStaffingTeam?: string | null;
    caseNumber?: string | null;
    eiInitialDate?: Date | null;
    active?: boolean | null;

    public constructor(params: EIClient = {} as EIClient) {
        let {
            eiClientId = 0,
            clientId = 0,
            eiReferralDate = null,
            eiStaffingTeam = "",
            caseNumber = "",
            eiInitialDate = null,
            active = null
        } = params;
        this.eiClientId = eiClientId,
        this.clientId = clientId,
        this.eiReferralDate = eiReferralDate && new Date(eiReferralDate),
        this.eiStaffingTeam = eiStaffingTeam,
        this.caseNumber = caseNumber,
        this.eiInitialDate = eiInitialDate && new Date(eiInitialDate),
        this.active = active
    }
 }