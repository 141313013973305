<template>
  <validation-provider :rules="validations" v-slot="{ errors }">
    <v-autocomplete
      :label="field.label"
      :value="val"
      :disabled="readonly"
      :items="field.options"
      :item-text="field.itemText"
      return-object
      @change="onChange"
      :error-messages="errors"
      color="#005c7b"
    ></v-autocomplete>
  </validation-provider>
</template>

<script>
export default {
  props: ["field", "value", "readonly", "validations"],
  computed: {
    val: {
      get: function () {
        return this.value && this.value[0];
      },
      set: function (newVal) {},
    },
  },
  methods: {
    onChange(event) {
      this.$emit("fieldChange", [event]);
    },
  },
};
</script>